/***********************************************
* Constants.js
* 
* Keep constant values for the application
***********************************************/

/* Production constants */
const prod = {
    API_URL: "https://" + window.location.hostname + "/api/",
    CAPTCHA_KEY: "6LdcXI4mAAAAACxZbeScsFuc5U5SF3xwyg000s7O",
};

/* Development constants */
const dev = {
    API_URL: "https://photomation.be/api/",
    CAPTCHA_KEY: "6LdcXI4mAAAAACxZbeScsFuc5U5SF3xwyg000s7O",
};

/* Determine constants when building */
export const config = process.env.NODE_ENV === 'development' ? dev : prod;