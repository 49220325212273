/***********************************************
* Login.js
* 
* Handles the login of the application
***********************************************/
/* Layout files */
import '../assets/style/Cookie.css';

/* Global imports */
import React from 'react';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import { withTranslation } from 'react-i18next';

/* Local imports */
import Storage from './Storage';

class Cookie extends React.Component {
    render() {
        return (
            <>
                <CookieBanner
                    message={this.props.t("usescookies")}
                    wholeDomain={true}
                    policyLink="/privacy"
                    showAcceptSelectionButton={true}
                    showPreferencesOption={false}
                    necessaryOptionText={this.props.t("necessary")}
                    statisticsOptionText={this.props.t("statsandana")}
                    marketingOptionText={this.props.t("marketing")}
                    acceptAllButtonText={this.props.t("acceptall")}
                    acceptSelectionButtonText={this.props.t("acceptselection")}
                    onAccept={() => {}}
                    onAcceptPreferences={() => {}}
                    onAcceptStatistics={() => { 
                        Storage.SetAnalytics(true); 
                        var _paq = window._paq = window._paq || [];
                        _paq.push(['rememberConsentGiven']);
                    }}
                    onAcceptMarketing={() => { Storage.SetMarketing(true); }}
                    styles={{
                      container: {},
                      dialog: { },
                      message: {},
                      policy: {},
                      selectPane: {},
                      optionWrapper: {},
                      optionLabel: {},
                      checkbox: {},
                      buttonWrapper: {},
                      button: {},
                    }}
                />
                {this.props.children}
            </>
        );
    }
}

export default withTranslation("Cookie")(Cookie);