/* Layout files */
import './assets/style/Constants.css';
import './assets/style/App.css';

/* Global imports */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect }   from 'react';
import { useTranslation } from 'react-i18next';

/* Local imports */
import Cookie                   from './components/Cookie'
import Download                 from './components/Download';
import ExamplePage              from './components/ExamplePage';
import LandingPage              from './components/LandingPage';
import Login                    from './components/Login';
import Overview                 from './components/Overview';
import PhotomationCore          from './components/PhotomationCore';
import UserCreate, { UserInfo } from './components/UserInfo';
import RenderMarkdown           from './components/RenderMarkdown';
import Storage                  from './components/Storage';

function App() {
    const { i18n,  } = useTranslation(["UserInfo"]);
    const tabletWidth = 1650;
    const phoneWidth = 500;
    const [isTablet, setIsTablet] = useState((window.innerWidth <= tabletWidth) && (window.innerWidth > phoneWidth));
    const [isPhone, setIsPhone]   = useState((window.innerWidth <= phoneWidth));
    const reload = () => window.location.reload();
    const [lang, setLanguage] = useState(Storage.GetLanguage());
    const languageList = ["en", "nl", "hr"];

    const language = {active: lang, set: setLanguage, list: languageList};

    const handleWindowSizeChange = () => {
        setIsPhone((window.innerWidth <= phoneWidth));
        setIsTablet((window.innerWidth <= tabletWidth) && (window.innerWidth > phoneWidth));
    };

    useEffect(() => {
        i18n.changeLanguage(lang);
        Storage.SetLanguage(lang);
    }, [lang, i18n]);

    useEffect(() => {
        var _paq = window._paq = window._paq || [];
        _paq.push(['requireConsent']);
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="https://analytics.photomation.be/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var g=document.createElement('script');
            var s=document.getElementsByTagName('script')[0];
            g.async=true; 
            g.src=u+'matomo.js'; 
            s.parentNode.insertBefore(g,s);
        })();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route path="landing"        element={<Cookie><LandingPage language={language}/></Cookie>} />
                    <Route index path=""         element={<Cookie><LandingPage language={language}/></Cookie>} />
                    <Route path="example"        element={<Cookie><ExamplePage language={language}/></Cookie>} />
                    {/*<Route path="prices"        element={<Cookie><RenderMarkdown language={language} markdownFile={window.location.origin + '/docs/Prices/'+language.active+'.md'} className="contentPage contentPagePrice"/></Cookie>} />*/}
                    <Route path="privacy"        element={<Cookie><RenderMarkdown language={language} markdownFile={window.location.origin + '/docs/Privacy/'+language.active+'.md'} className="contentPageMd"/></Cookie> }/>
                    <Route path="termsofservice" element={<Cookie><RenderMarkdown language={language} markdownFile={window.location.origin + '/docs/TermsOfService/'+language.active+'.md'} className="contentPageMd"/></Cookie> }/>
                    <Route path="projects"       element={<Login language={language}><PhotomationCore tablet={isTablet} phone={isPhone}/></Login>} />
                    <Route path="user"           element={<Login language={language}><UserInfo/></Login>} />
                    <Route path="createuser"     element={<Login language={language} nologin={true}><UserCreate /></Login>} />
                    <Route path="download"       element={<Login language={language}><Download /></Login>} />
                    <Route path="overview"       element={<Login language={language}><Overview /></Login>} />
                    <Route path="/Markers.pdf"   onEnter={reload} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
