/***********************************************
* Storage.js
* 
* An interface to the local storage
***********************************************/

export default class Storage {
    /* Get the backend token from local storage
    *  If it expired, remove it 
    */
    static GetToken() {
        var now = Date.now();
        var expiresIn = localStorage.getItem("userToken_ExpiresIn");
        if (expiresIn===undefined || expiresIn===null) { expiresIn = 0; }

        if (expiresIn < now) {
            Storage.RemoveToken();
            return null;
        } else {
            try {
                var user = {};
                user["username"] = localStorage.getItem("username");
                user["userToken"]= localStorage.getItem("userToken");
                return user;
            } catch(e) {
                console.log("SetToken: Error getting token " + JSON.stringify(e));
                return null;
            }
        }
    }

    /* Save the backend token in local storage
    *  Add an expiry date 7 days later
    */
    static SetToken(username, token, expires) {
        if(expires === undefined || expires == null) {
            expires = (24*60*60*7);
            //expires = 60;
        } else {
            expires = Math.abs(expires)
        }

        var now = Date.now();
        var schedule = now + (expires * 1000);

        try {
            localStorage.setItem("username", username);
            localStorage.setItem("userToken", token);
            localStorage.setItem("userToken_ExpiresIn", schedule);
        } catch(e) {
            console.log("SetToken: Error setting token " + JSON.stringify(e));
        }
    }

    /* Remove token from local storage */
    static RemoveToken() {
        try {
            localStorage.removeItem("userToken");
            localStorage.removeItem("userToken_ExpiresIn");
            localStorage.removeItem("username");
        } catch(e) {
            console.log("RemoveToken: Error removing token " + JSON.stringify(e));
        }
    }

    /* Save the analytics cookie preference in local storage */
    static SetAnalytics(value) {  localStorage.setItem("analytics", value);  }

    /* Check if the analytics cookie preference was set */
    static CheckAnalytics() {
        var analytics = localStorage.getItem("analytics");
        if(analytics === "true") {
            return true;
        } else {
            return false;
        }
    }

    /* Save the marketing cookie preference in local storage */
    static SetMarketing(value) {  localStorage.setItem("marketing", value);  }

    /* Check if the marketing cookie preference was set */
    static CheckMarketing() {
        var marketing = localStorage.getItem("marketing");
        if(marketing === "true") {
            return true;
        } else {
            return false;
        }
    }

    /* Save the language preference in local storage */
    static SetLanguage(value) {  localStorage.setItem("language", value);  }

    /* Read the language preference from local storage
    *  If not set or invalid, default to "en"
    */
    static GetLanguage() { 
        var language = localStorage.getItem("language"); 
        if(language === undefined || language === "null" || language === "") {
            language = "en";
        }
        return language;
    }
}